<template>
  <div class="body">
    <div class="statistical-top">
      <el-tabs v-model="activeName" @tab-click="clickTab" class="el-nav">
        <el-tab-pane label="柱状图" name="first">
          <!-- 搜索查询-->
          <div class="search space-between">
            <div class="flex-nowrap vertical-center">
              时间
              <el-date-picker class="search-time" v-model="value" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
              <el-button type="primary" class="search-btn" @click="searchSubmit">查询</el-button>
              <el-button class="search-btn reset">重置</el-button>
            </div>
          </div>
          <!--    统计表开始-->
          <div class="statistical-bottom">
            <div class="statistical-bottom-title vertical-center space-between"></div>

            <div class="echarts align-center">
              <div class="title">
                <img src="~assets/images/data/gold.png" alt="" />
                <span>奖励发放</span>
              </div>
              <div id="myChart" :style="{ width: '100%', height: '480px' }"></div>
            </div>
          </div>
          <!--    统计表结束-->
        </el-tab-pane>
        <el-tab-pane label="图表" name="two">
          <!--    上部开始-->
          <div class="statistical-center">
            <div class="statistical-center-list space-between">
              <div class="statistical-center-item " :class="tabIndex === 1 ? 'active' : ''" @click="chsoeTab(1)">
                <div class="statistical-center-item-title">
                  课程
                </div>
                <div class="statistical-center-item-num">
                  {{ curriculum }}
                </div>
                <el-divider></el-divider>
                <div class="statistical-center-item-foot">
                  昨日：
                  <el-tag :class="oldCurriculum === 0 ? 'noBg' : oldCurriculum > 0 ? 'blueBg' : 'redBg'"
                    >{{ oldCurriculum === 0 ? '' : oldCurriculum > 0 ? '+' : '' }}{{ oldCurriculum }}</el-tag
                  >
                </div>
              </div>
              <div class="statistical-center-item" :class="tabIndex === 2 ? 'active' : ''" @click="chsoeTab(2)">
                <div class="statistical-center-item-title">
                  文章
                </div>
                <div class="statistical-center-item-num">
                  {{ articleNum }}
                </div>
                <el-divider></el-divider>
                <div class="statistical-center-item-foot">
                  昨日：
                  <el-tag :class="oldArticleNum === 0 ? 'noBg' : oldArticleNum > 0 ? 'blueBg' : 'redBg'"
                    >{{ oldArticleNum === 0 ? '' : oldArticleNum > 0 ? '+' : '' }}{{ oldArticleNum }}</el-tag
                  >
                </div>
              </div>
              <div class="statistical-center-item" :class="tabIndex === 3 ? 'active' : ''" @click="chsoeTab(3)">
                <div class="statistical-center-item-title">
                  视频
                </div>
                <div class="statistical-center-item-num">
                  {{ videoNum }}
                </div>
                <el-divider></el-divider>
                <div class="statistical-center-item-foot">
                  昨日：
                  <el-tag :class="oldVideoNum === 0 ? 'noBg' : oldVideoNum > 0 ? 'blueBg' : 'redBg'">{{ oldVideoNum === 0 ? '' : oldVideoNum > 0 ? '+' : '' }}{{ oldVideoNum }}</el-tag>
                </div>
              </div>
              <div class="statistical-center-item" :class="tabIndex === 4 ? 'active' : ''" @click="chsoeTab(4)">
                <div class="statistical-center-item-title">
                  直播
                </div>
                <div class="statistical-center-item-num">
                  {{ liveNum }}
                </div>
                <el-divider></el-divider>
                <div class="statistical-center-item-foot">
                  昨日：
                  <el-tag :class="oldLiveNum === 0 ? 'noBg' : oldLiveNum > 0 ? 'blueBg' : 'redBg'">{{ oldLiveNum === 0 ? '' : oldLiveNum > 0 ? '+' : '' }}{{ oldLiveNum }}</el-tag>
                </div>
              </div>
              <div class="statistical-center-item" :class="tabIndex === 5 ? 'active' : ''" @click="chsoeTab(5)">
                <div class="statistical-center-item-title">
                  频道
                </div>
                <div class="statistical-center-item-num">
                  {{ channelNum }}
                </div>
                <el-divider></el-divider>
                <div class="statistical-center-item-foot">
                  昨日：
                  <el-tag :class="oldChannelNum === 0 ? 'noBg' : oldChannelNum > 0 ? 'blueBg' : 'redBg'"
                    >{{ oldChannelNum === 0 ? '' : oldChannelNum > 0 ? '+' : '' }}{{ oldChannelNum }}</el-tag
                  >
                </div>
              </div>
            </div>
          </div>
          <!--上部结束-->
          <!--        搜索查询-->
          <div class="search space-between ">
            <div class="flex-nowrap top-list vertical-center">
              <el-form ref="chartForm" :model="chartForm" label-width="80px">
                <el-select v-model="chartForm.chartType" placeholder="昵称" class="search-types">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>

                <el-input v-model="chartForm.condition" placeholder="请输入查询条件" class="search-input"></el-input>
                注册时间
                <el-date-picker class="search-time" v-model="chartForm.time_value" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
                <el-button type="primary" class="search-btn ">查询</el-button>
                <el-button class="search-btn reset">重置</el-button>
              </el-form>
            </div>
            <!--          <el-button type="warning" class="f-r search-add" @click="add()">新增课程</el-button>-->
          </div>

          <div class="total space-between">
            <div class="total-left">共有 <span class="total-left-text">17</span> 条数据</div>
          </div>

          <!--表格-->
          <el-table
            class="table"
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{ background: '#F6F8F9', color: '#1C1F21', fontWeight: '400' }"
            border
          >
            <el-table-column prop="date" label="编号" width="72"> </el-table-column>
            <el-table-column label="头像" width="84" align="center">
              <template slot-scope="scope">
                <img src="#" class="five-avatar" />
              </template>
            </el-table-column>
            <el-table-column prop="name" label="学员">
              <template slot-scope="scope">
                <div class="">昵称：弘毅</div>
                <div class="">等级：1级</div>
              </template>
            </el-table-column>
            <el-table-column prop="date" label="金币数" width="100"> </el-table-column>
            <el-table-column prop="date" label="浏览" width="100"> </el-table-column>
            <el-table-column prop="date" label="评论" width="100"> </el-table-column>
            <el-table-column prop="date" label="回复" width="100"> </el-table-column>
            <el-table-column prop="time" label="注册时间"> </el-table-column>
          </el-table>
          <el-pagination
            class="page"
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="1"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="100"
            layout=" prev, pager, next,total, jumper"
            :total="400"
          >
          </el-pagination>
        </el-tab-pane>
      </el-tabs>
      <div class="el-nav-right">
        每天
        <span>1</span>
        点左右更新前一日数据
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {
      activeName: 'first',
      value: '',
      curriculum: 0,
      articleNum: 0,
      videoNum: 0,
      liveNum: 0,
      channelNum: 0,
      oldCurriculum: 15,
      oldArticleNum: 15,
      oldVideoNum: -9,
      oldLiveNum: 0,
      oldChannelNum: 0,
      tabIndex: 1,
      chartForm: {
        chartType: 1,
        condition: '',
        time_value: '',
      },
      options: [
        {
          label: '昵称',
          value: 1,
        },
        {
          label: '编号',
          value: 2,
        },
      ],
      tableData: [
        {
          date: '2016',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
          time: '2020-10-10',
        },
        {
          date: '2016',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
          time: '2020-10-10',
        },
        {
          date: '2016',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
          time: '2020-10-10',
        },
        {
          date: '2016',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
          time: '2020-10-10',
        },
        {
          date: '2016',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
          time: '2020-10-10',
        },
        {
          date: '2016',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
          time: '2020-10-10',
        },
        {
          date: '2016',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
          time: '2020-10-10',
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.drawLine();
  },
  methods: {
    // 切换tab
    clickTab() {
      this.tabIndex = 1;
      this.value = '';
    },
    // 类型切换
    chsoeTab(num) {
      if (this.tabIndex !== num) {
        this.tabIndex = num;
      }
    },
    handleSizeChange() {},
    handleCurrentChange() {},
    // 查询
    searchSubmit() {},
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChart'));
      // 绘制图表
      myChart.setOption({
        title: {
          // text: '奖励发放',
          textStyle: {
            fontSize: 16,
            color: '#71777D',
            fontWeight: 400,
          },
        },
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: ['积分', '经验值'],
          itemHeight: 14,
          itemWidth: 14,
          itemGap: 60,
          right: '2%',
          icon: 'rect',
          textStyle: {
            padding: [0, 0, 0, 12],
          },
          //
        },
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          axisLine: {
            //不显示坐标轴线
            show: false,
          },
          axisTick: {
            //不显示坐标轴刻度线
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
            },
          },
        },
        grid: {
          left: '40px',
          right: '20px',
        },
        series: [
          {
            name: '积分',
            data: [120, 132, 101, 134, 90, 230, 210],
            type: 'bar',
            smooth: true,
            color: '#FFA509',
            barWidth: 25, // 柱图宽度
          },
          {
            name: '经验值',
            data: [420, 532, 401, 534, 790, 1130, 720],
            type: 'bar',
            smooth: true,
            color: '#008AFF',
            barWidth: 25, // 柱图宽度
          },
        ],
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/data/gold/index.less';
</style>
